import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { CritterService } from '../services/critters.service';


export const logGuard: CanActivateFn = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const authService = inject(AuthenticationService);
    const critterService = inject(CritterService);
    const router = inject(Router);

    const id = route.paramMap.get('id');
    const currentUser: any = authService.currentUser.value;
    if (currentUser?._id) {
        try {
            const res = await critterService.getLogActivityStatus(id as string).toPromise();
            if (res?.data) {
                return true;
            } else {
                router.navigate(['/dashboard']);
                return false;
            }
        } catch (error) {
            router.navigate(['/dashboard']);
            return false;
        }
    } else {
        router.navigate(['/'], { queryParams: { returnUrl: state.url } });
        return false;
    }
};
