import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';


export const authGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const authService = inject(AuthenticationService)
    const router = inject(Router)
    const currentUser: any = authService.currentUser.value
    if (currentUser?._id) {
        return true
    } else {
        router.navigate(['/'], { queryParams: { returnUrl: state.url } });
        return false
    }
}
