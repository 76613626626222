import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';


export const loggedInGuard: CanActivateFn = () => {
    const authService = inject(AuthenticationService)
    const router = inject(Router)
    const currentUser = authService.currentUser.value
    if (currentUser?._id) {
        router.navigate(['/dashboard'])
        return false
    } else {
        return true
    }
}
