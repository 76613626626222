import { Routes } from '@angular/router';
import { authGuard } from './core/guards/auth.guard';
import { logGuard } from './core/guards/log.guard';
import { loggedInGuard } from './core/guards/logged-in.guard';

export const routes: Routes = [
  {
    path: '',
    canActivate: [loggedInGuard],
    loadComponent: () =>
      import('./features/home/home.component').then((m) => m.HomeComponent),
  },
  {
    path: 'login',
    canActivate: [loggedInGuard],
    loadComponent: () =>
      import('./features/login/login.component').then((m) => m.LoginComponent),
  },
  {
    path: 'forgot-password',
    canActivate: [loggedInGuard],
    loadComponent: () =>
      import('./features/forgot-password/forgot-password.component').then(
        (m) => m.ForgotPasswordComponent
      ),
  },
  {
    path: 'signup',
    canActivate: [loggedInGuard],
    loadComponent: () =>
      import('./features/signup/signup.component').then(
        (m) => m.SignupComponent
      ),
  },
  {
    path: 'auth',
    canActivate: [loggedInGuard],
    loadComponent: () =>
      import('./features/google-auth/google-auth.component').then(
        (m) => m.GoogleAuthComponent
      ),
  },
  {
    path: 'create-profile',
    loadComponent: () =>
      import('./features/create-profile/create-profile.component').then(
        (m) => m.ProfileComponent
      ),
  },
  {
    path: 'signup-success',
    loadComponent: () =>
      import('./features/signup/components/success/success.component').then(
        (m) => m.SuccessComponent
      ),
  },
  {
    path: 'auth/signup-success',
    loadComponent: () =>
      import('./features/google-auth/components/success/success.component').then(
        (m) => m.GoogleAuthSuccessComponent
      ),
  },
  {
    path: 'auth/create-profile',
    loadComponent: () =>
      import('./features/google-auth/components/google-signup/profile.component').then(
        (m) => m.GoogleSignupComponent
      ),
  },
  {
    path: 'dashboard',
    canActivate: [authGuard],
    loadComponent: () =>
      import('./layouts/dashboard-layout/dashboard-layout.component').then(
        (m) => m.DashboardLayoutComponent
      ),
  },
  {
    path: 'account',
    loadComponent: () =>
      import(
        './layouts/dashboard-layout/components/profile/profile.component'
      ).then((m) => m.ProfileComponent),
    canActivate: [authGuard],
  },
  {
    path: 'referral',
    loadComponent: () =>
      import(
        '../app/layouts/dashboard-layout/components/referral/referral.component'
      ).then((m) => m.ReferralComponent),
    canActivate: [authGuard],
  },
  {
    path: 'critters',
    loadComponent: () =>
      import(
        './layouts/dashboard-layout/components/critters/critters.component'
      ).then((m) => m.CrittersComponent),
    canActivate: [authGuard],
  },
  {
    path: 'how-to-play',
    loadComponent: () =>
      import(
        './layouts/dashboard-layout/components/log-activity/components/how-to-play/how-to-play.component'
      ).then((m) => m.HowToPlayComponent),
  },
  {
    path: 'status/:id',
    loadComponent: () =>
      import(
        './layouts/dashboard-layout/components/log-activity-form/components/game-status/game-status.component'
      ).then((m) => m.GameStatusComponent),
    canActivate: [authGuard],
  },
  {
    path: 'current-challenge/:id',
    loadComponent: () =>
      import(
        './layouts/dashboard-layout/components/log-activity/log-activity.component'
      ).then((m) => m.LogActivityComponent),
    canActivate: [authGuard],
  },
  {
    path: 'critters/detail/:id',
    loadComponent: () =>
      import(
        './layouts/dashboard-layout/components/critter-detail/critter-detail.component'
      ).then((m) => m.CritterDetailComponent),
    canActivate: [authGuard],
  },
  {
    path: 'critters/log-activity/:id',
    loadComponent: () =>
      import(
        './layouts/dashboard-layout/components/log-activity-form/log-activity-form.component'
      ).then((m) => m.LogActivityFormComponent),
    canActivate: [authGuard, logGuard],
  },
  {
    path: 'critters/log-activity-single/:id',
    loadComponent: () =>
      import('../app/layouts/dashboard-layout/components/log-activity-single/log-activity-single.component').then((m) => m.LogActivitySingleComponent),
    canActivate: [authGuard, logGuard],
  },
  {
    path: 'leaderboard',
    loadComponent: () =>
      import(
        './layouts/dashboard-layout/components/leaderboard/leaderboard.component'
      ).then((m) => m.LeaderboardComponent),
    canActivate: [authGuard],
  },
  {
    path: 'mini-tasks',
    loadComponent: () =>
      import(
        './layouts/dashboard-layout/components/mini-task/components/all-mini-tasks/all-mini-tasks.component'
      ),
    canActivate: [authGuard],
  },
  {
    path: 'mini-task/:id',
    loadComponent: () =>
      import(
        './layouts/dashboard-layout/components/mini-task/mini-task.component'
      ),
    canActivate: [authGuard],
  },
];
